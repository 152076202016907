import React from "react";
import { PhoneIcon, ArrowLeftIcon } from "@heroicons/react/24/solid";

const HelpResultInfo = ({ resultData }) => {
  return (
    <div className="flex flex-col mt-3">
          <p className="mx-12 md:max-w-2xl self-center text-center">
            {resultData.description}
          </p>
          <div className="text-center m-6">
            <a
              href="https://meetings.hubspot.com/czuba/twinwin?utm_source=homepagequiz"
              target="_blank"
              rel="noreferrer"
              className="text-center inline-flex items-center p-3 bg-orange-500 hover:bg-orange-400 text-white rounded font-semibold"
            >
              <PhoneIcon className="w-6 mr-3"></PhoneIcon>
              {resultData.callButtonText}
            </a>
          </div>
          <div className="mt-6 mx-auto md:mx-12 p-6">
            <a
              href="https://www.twin.win/"
              className="inline-flex items-center p-3 bg-yellow-500 hover:bg-yellow-400 text-white rounded font-semibold"
            >
              <ArrowLeftIcon className="w-6 mr-3"></ArrowLeftIcon>
              {resultData.backButtonText}
            </a>
          </div>
        </div>
  );
};

export default HelpResultInfo;
