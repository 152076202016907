import React, { useEffect, useState } from "react";
import deResultData from "./resultDataDe.json";
import enResultData from "./resultDataEn.json";
import ResultInfo from "./ResultInfo";
import HelpResultInfo from "./HelpResultInfo";
import QuizViewComponent from "./QuizViewComponent";
import { CalendarIcon, ArrowLeftIcon } from "@heroicons/react/24/solid";

const Result = ({ lang, email, correctAnswers, formResults }) => {
  let resultData = deResultData;
  if (lang === "en") {
    resultData = enResultData;
  }

  const attribute = resultData.attributes.find((attribute) =>
    attribute["correctAnswers"].includes(correctAnswers)
  );

  const resultDescription = resultData.resultDescription.replace(
    "$numberOfCorrectAnswers",
    correctAnswers
  );

  let hScreen = "h-screen";
  if (formResults) {
    hScreen = "";
  }

  return (
    <div className={`flex items-center justify-center ${hScreen} p-6`}>
      <div className="border border-orange-100 bg-white rounded p-5 shadow-lg sm:w-4/6 max-w">
        <div className="m-6 mx-12">
          <h2 className="text-2xl font-bold">{resultData.title}</h2>
          <p className="text-sm text-gray-500">{email}</p>
        </div>
        <hr className="my-6" />
        <ResultInfo
          attribute={attribute}
          resultDescription={resultDescription}
        />
        <div className="flex flex-col mt-3">
          {formResults ? (
            <QuizViewComponent
              resultData={resultData}
              formResults={formResults}
            />
          ) : (
            <div className="mx-12 md:max-w-2xl self-center text-center">
              <h3 className="font-semibold">{resultData.descriptionHeading}</h3>
              <p>{resultData.description}</p>
            </div>
          )}

          <div className="text-center m-6">
            <a
              href="https://meetings.hubspot.com/czuba/twinwin?utm_source=homepagequiz"
              target="_blank"
              rel="noreferrer"
              className="text-center inline-flex items-center p-3 bg-orange-500 hover:bg-orange-400 text-white rounded font-semibold"
            >
              <CalendarIcon className="w-6 mr-3" />
              {resultData.callButtonText}
            </a>
          </div>
          <div className="mt-6 mx-auto md:mx-12 p-6">
            <a
              href="https://www.twin.win/"
              className="inline-flex items-center p-3 bg-yellow-500 hover:bg-yellow-400 text-white rounded font-semibold"
            >
              <ArrowLeftIcon className="w-6 mr-3" />
              {resultData.backButtonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
