import React, {useEffect, useState} from "react";
import ResultAnswer from "./ResultAnswer";
import {isAnsweredCorrectly} from "./utils";

const ResultQuestion = ({block, results, resultData}) => {
    const userAnswers = results[block.id];

    const isCorrect = isAnsweredCorrectly(userAnswers, block);

    return (
        <div className="flex flex-col rounded-md mt-5 border-t border-gray-200 p-3">
            <h3 className="mx-5 font-semibold">{isCorrect ?
                <span className="font-black text-xl mr-1 text-green-700">✓</span> :
                <span className="font-black text-xl mr-1 text-red-700">x</span>}{resultData.question}</h3>
            <p
                className="p-3 bg-blue-100 rounded-md mx-3 m-1"
                dangerouslySetInnerHTML={{__html: block.attributes.label}}
            ></p>
            <p className="font-semibold mx-5 mt-2">{resultData.yourAnswer}</p>
            <ul>
                {block.attributes.choices.map((choice) => {
                    const isSelected = userAnswers.includes(choice.value);
                    if (isSelected) {
                        return <ResultAnswer choice={choice}></ResultAnswer>;
                    }
                })}
            </ul>
            <p className="font-semibold mx-5 mt-2">{resultData.solution}</p>
            <ul>
                {block.attributes.choices.map((choice) => {
                    if (choice.value.includes("true")) {
                        return <ResultAnswer choice={choice}></ResultAnswer>;
                    }
                })}
            </ul>
            <p className="font-semibold mx-5 mt-2">{resultData.detailedExplanation}</p>
            <p
                style={{maxWidth: "100%"}}
                className="p-3 bg-gray-100 rounded-md mx-3 m-1 prose"
                dangerouslySetInnerHTML={{__html: block.attributes.detailedExplanationForAnswer}}
            ></p>
        </div>
    );
};

export default ResultQuestion;
