import React, { useEffect, useState } from "react";

const ResultAnswer = ({ choice }) => {
  let color = "";

  color = "red";
  if (choice.value.includes("true")) {
    color = "green";
  }

  // bg-red-100
  // bg-green-100
  // bg-stone-100


  return (
    <li className={`flex p-3 mx-3 m-1 bg-${color}-100 rounded-md`}>
      <p dangerouslySetInnerHTML={{ __html: choice.label }}></p>
    </li>
  );
};

export default ResultAnswer;
