export const isAnsweredCorrectly = (userAnswers, answeredBlock) => {
  const hasWrongAnswer = userAnswers.find((answer) => answer.includes("wrong"));
  if (hasWrongAnswer) {
    return false;
  }

  const correctUserAnswerValues = userAnswers.filter((choice) =>
    choice.includes("true")
  );
  const correctBlockChoiceValues = answeredBlock.attributes.choices.filter(
    (choice) => choice.value.includes("true")
  );

  return correctUserAnswerValues.length === correctBlockChoiceValues.length;
};

export const calculateNumberOfCorrectAnswers = (userAnswersObject, blocks) => {
  let numberOfCorrectAnswers = 0;
  for (const key in userAnswersObject) {
    const userAnswers = userAnswersObject[key];
    const answeredBlock = blocks.find((block) => block.id === key);

    const isCorrect = isAnsweredCorrectly(userAnswers, answeredBlock);
    if (isCorrect) {
      numberOfCorrectAnswers++;
    }
  }
  return numberOfCorrectAnswers;
};
