import React, {useEffect, useState} from "react";
import {Form} from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import {registerCoreBlocks} from "@quillforms/react-renderer-utils";
import deFormData from "./formDataDe.json";
import enFormData from "./formDataEn.json";
import Result from "./Result";
import "./output.css";
import {calculateNumberOfCorrectAnswers} from "./utils";

registerCoreBlocks();

const App = () => {
    const [lang, setLang] = useState("de");
    const [email, setEmail] = useState("");
    const [showResults, setShowResults] = useState(false);
    const [numberOfCorrectAnswers, setNumberOfCorrectAnswers] = useState(0);
    const [formResults, setFormResults] = useState("");
    const [formObj, setFormObj] = useState(deFormData);

    const setFormLanguage = (languageStr) => {
        setLang(languageStr);
        if (languageStr === "en") {
            setFormObj(enFormData);
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const langParam = searchParams.get("lang");
        const dataJsonBase64 = searchParams.get("data_json_base64");

        if (langParam) {
            setFormLanguage(langParam);
        }
        if (dataJsonBase64 !== null) {
            const urlResults = JSON.parse(b64DecodeUnicode(dataJsonBase64));

            setFormResults(urlResults);

            setFormLanguage(urlResults["language"]);

            setEmail(urlResults["email"]);

            const numberOfCorrectAnswers = calculateNumberOfCorrectAnswers(
                urlResults["results"],
                formObj.blocks
            );
            setNumberOfCorrectAnswers(numberOfCorrectAnswers);

            setShowResults(true);
        }
    }, []);

    function b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(
            atob(str)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
    }

    async function sendEmail(language, email, results) {
        try {
            const requestOptions = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    email: email,
                    language: language,
                    results: results,
                }),
            };
            const response = await fetch(
                "https://app.twin.win/hubspot/homepage_quiz/submit/",
                requestOptions
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }

    const submitForm = (data) => {
        let userAnswersObject = {};

        for (const key in data.answers) {
            if (key.startsWith("q")) {
                const userAnswers = data.answers[key].value;
                userAnswersObject[key] = userAnswers;
            }
        }

        const numberOfCorrectAnswers = calculateNumberOfCorrectAnswers(
            userAnswersObject,
            formObj.blocks
        );
        setNumberOfCorrectAnswers(numberOfCorrectAnswers);

        setEmail(data.answers.email.value);
        setShowResults(true);
        sendEmail(lang, data.answers.email.value, userAnswersObject);
    };

    return (
        <div style={{width: "100%", height: "100vh"}}>
            {showResults ? (
                <Result
                    lang={lang}
                    email={email}
                    correctAnswers={numberOfCorrectAnswers}
                    formResults={formResults}
                />
            ) : (
                <Form
                    formId="1"
                    formObj={formObj}
                    onSubmit={(
                        data,
                        {completeForm, setIsSubmitting, goToBlock, setSubmissionErr}
                    ) => {
                        submitForm(data);
                        /*setTimeout(() => {
                          setIsSubmitting(false);
                        }, 500);*/
                    }}
                />
            )}
        </div>
    );
};

export default App;
