import React from "react";
import {
  SparklesIcon,
  LightBulbIcon,
  AcademicCapIcon,
  ScaleIcon,
} from "@heroicons/react/24/solid";

// bg-orange-600 bg-orange-50
// bg-blue-600 bg-blue-50
// bg-green-600 bg-green-50

const getHeroIcon = (icon) => {
  switch (icon) {
    case "sparkles":
      return <SparklesIcon className="w-8 text-white"></SparklesIcon>;
    case "light-bulb":
      return <LightBulbIcon className="w-8 text-white"></LightBulbIcon>;
    case "academic-cap":
      return <AcademicCapIcon className="w-8 text-white"></AcademicCapIcon>;
    case "scale":
      return <ScaleIcon className="w-8 text-white"></ScaleIcon>;
    default:
      return <SparklesIcon className="w-8 text-white"></SparklesIcon>;
  }
};

const ResultInfo = ({ attribute, resultDescription }) => {
  return (
    <div
      className={`m-1 p-6 md:m-6 md:p-12 bg-${attribute.color}-50 rounded-xl`}
    >
      <div className="flex items-center">
        <div className={`bg-${attribute.color}-600 rounded-full p-4`}>
          {getHeroIcon(attribute.icon)}
          {/* <SparklesIcon className="w-8 text-white"></SparklesIcon> */}
        </div>
        <div className={`ml-6 text-${attribute.color}-600`}>
          <h3 className="text-2xl font-semibold">{attribute.label}</h3>
          <p className="mt-2">{resultDescription}</p>
          <p>{attribute.explanation}</p>
        </div>
      </div>
    </div>
  );
};

export default ResultInfo;
