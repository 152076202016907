import React from "react";
import deFormData from "./formDataDe2.json";
import enFormData from "./formDataEn2.json";
import ResultQuestion from "./ResultQuestion";

const QuizViewComponent = ({formResults, resultData}) => {
    let formObj = deFormData;
    if (formResults.language === "en") {
        formObj = enFormData;
    }


    return (
        <div>
            <h1 className="text-2xl font-bold text-center">{resultData.answerKey}</h1>
            <div className="m-2">
                {formObj.blocks.filter((block) => (block.id.includes("q"))).map((blockParam) => (
                    <ResultQuestion block={blockParam} results={formResults.results}
                                    resultData={resultData}></ResultQuestion>))}
            </div>
        </div>
    );
};

export default QuizViewComponent;
